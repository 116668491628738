<template>
  <div>
    <b-alert
      show
      variant="warning"
      align="center"
      class="mx-auto w-50"
      v-if="$auth.user().funnels.includes('organization.create')"
    >
      <div class="alert-body mw-100" style="width: 768px">
        <h4>
          <strong>
            {{ $t("organization.self.call-to-action-title") }}
          </strong>
        </h4>
        <p class="text-left py-1">
          {{ $t("organization.self.call-to-action-description") }}
        </p>
      </div>
    </b-alert>
    <organization-form
      :initial-organization-id="organizationId"
      :on-success="onSuccess"
    />
  </div>
</template>

<script>
import OrganizationForm from "@/views/admin/organizations/Form.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "CreateOrganization",
  components: { OrganizationForm },
  computed: {
    organizationId() {
      return this.$auth.user()?.organization?.id;
    },
  },
  methods: {
    async onSuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("organization.self.success"),
          icon: "CheckIcon",
          variant: "success",
        },
      });

      await this.$auth.fetch();
      this.$router.push({ name: "person-list" });
    },
  },
};
</script>

<style>
</style>